import React from 'react';
//Router
import { Link } from 'react-router-dom';
//Youtube Embed
import YoutubeEmbed from "../components/HomeComponents/YoutubeEmded";
//Style CSS
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-main">
            <div className="footer-container container">
                <div className="footer-flex">
                    <div className="first-box box">
                        <h5 className="footer-title yt-footer-title">MAMS LT&G Franchising Corp.</h5>
                        <div className="yt-footer">
                            <YoutubeEmbed embedId="eWN9L6lmy00" />
                        </div>
                    </div>
                    <div className="second-box box">
                        <div className="flex-box">
                            <div className="flex-cont">
                                <div className="fifty-cont cent cent-1">
                                    <h2 className="cent-h2">Est. Since</h2>
                                    <h2 className="cent-h2">2015</h2>
                                </div>
                                <div className="fifty-cont cent cent-2">
                                    <h3 className="cent-h3">37</h3>
                                    <h4 className="cent-h4">LT&G Branches</h4>
                                </div>
                            </div>
                            <div className="flex-cont">
                                <div className="fifty-cont cent cent-3">
                                    <h3 className="cent-h3">82,954</h3>
                                    <h4 className="cent-h4">Happy Customers</h4>
                                </div>
                                <div className="fifty-cont cent cent-4">
                                    <h3 className="cent-h3">13</h3>
                                    <h4 className="cent-h4">Upcoming Branches</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-box box">
                    <div className="flex-cont">
                        <div className="fifty-cont">
                            <h5>Main Office</h5>
                            <p className="address">2/F, Soliman Bldg, Imelda Roces Ave, Tahao Road, Legazpi City</p>
                        </div>
                        <div className="fifty-cont">
                            <h5>Manila Office</h5>
                            <p className="address">2/F Unit V, TFN Building, 963 Gen Kalentong Cor. Haig St, Daang Bakal, Mandaluyong, 1550 Metro Manila</p>
                        </div>
                        <div className="fifty-cont">
                            <h5>Cebu Office</h5>
                            <p className="address">2/F Arbs Building, Corner Ernesto, Bascon St., Dumlog, Talisay City, Cebu</p>
                        </div>
                    </div>
                    <div className="flex-cont">
                        <div className="fifty-cont">
                            <h5>Useful Links</h5>
                            <ul className="footer-link">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/testimonials">Testimonials</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                        <div className="fifty-cont">
                            <h5>Franchise Programs</h5>
                            <ul className="footer-link">
                                <li><Link to="/preopening">Pre-Opening Support</Link></li>
                                <li><Link to="/opening">Opening Support</Link></li>
                                <li><Link to="/continuing-support">Continuing Support</Link></li>
                                <li><Link to="/marketing-support">Marketing Support</Link></li>
                                <li><Link to="/franchise-training">Franchise Training</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;